@import "mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "maps";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

.is-hover-link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.is-hover {
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 5px 6px 6px 2px #e9ecef;
    transform: scale(1.05);
  }
}

.clamp-text-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.clamp-text-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.nav-link.active {
  border-bottom: 2px solid $primary;
}

article.content figure {
  margin: 0;
  padding: 0;
  text-align: center;
}

article.content img,
article.content iframe {
  max-width: 100%;
  max-height: 250px;
  object-fit: contain;
  text-align: center;
  display: block;
  margin: 4px auto;
}

article.content h2 {
  color: #fff;
  background-color: $primary;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  font-size: 1.5rem;
}

article.content h3 {
  font-weight: bold;
  border-bottom: 2px solid $primary;
  font-size: 1.25rem;
}
